import type { ReactNode } from 'react';
import { useState, useEffect, useCallback } from 'react';
import { cn } from '@rocco/utils/cn';
import { cva, type VariantProps } from 'class-variance-authority';
import { useAtom } from 'jotai';
import { uiStateAtomReachedFooter } from '@rocco/ui/website-footer/states/ui-states-reached-footer';

interface FloatingIslandProps
    extends VariantProps<typeof _floatingIslandContentVariants> {
    children: ReactNode;
    scrollThreshold?: number;
    onVisibilityChange?: (isVisible: boolean) => void;
    onClick?: () => void;
}

const _floatingIslandVariants = cva(
    'fixed bottom-8 md:bottom-10 left-1/2 z-sticky w-full -translate-x-1/2 px-[1.6875rem] py-4 md:px-0 md:py-0',
    {
        variants: {
            variant: {
                default: 'md:max-w-[56.125rem]',
                narrow: 'md:max-w-[38.375rem]',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
);

const _floatingIslandContentVariants = cva(
    'rounded-full bg-gray-100/65 shadow-island backdrop-blur-island transition-all duration-300',
    {
        variants: {
            variant: {
                details: 'p-2 md:pr-6',
                detailsNoImage: 'p-2 pl-7.5 md:pr-6 md:pl-13.5',
                category: 'p-2 md:py-2 md:pr-2 md:pl-13',
                default: 'p-[0.3125rem] md:p-4',
                home: 'p-2 md:py-2 md:pr-2 md:pl-[3.1375rem]',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
);

export const FloatingIsland = ({
    children,
    scrollThreshold = 200,
    variant = 'default',
    onVisibilityChange,
    onClick,
}: FloatingIslandProps) => {
    const [isVisible, setIsVisible] = useState(false);
    const [reachedFooter] = useAtom(uiStateAtomReachedFooter);

    const handleScroll = useCallback(() => {
        let frameId: number | null = null;

        return () => {
            if (frameId !== null) {
                cancelAnimationFrame(frameId);
            }

            frameId = requestAnimationFrame(() => {
                const newIsVisible =
                    scrollThreshold === 0
                        ? !reachedFooter
                        : window.scrollY > scrollThreshold && !reachedFooter;

                setIsVisible(prev => {
                    if (prev !== newIsVisible) {
                        onVisibilityChange?.(newIsVisible);
                        return newIsVisible;
                    }
                    return prev;
                });
            });
        };
    }, [scrollThreshold, onVisibilityChange, reachedFooter]);

    useEffect(() => {
        const scrollListener = handleScroll();
        window.addEventListener('scroll', scrollListener, { passive: true });

        const frameId = requestAnimationFrame(() => {
            if (scrollThreshold === 0) {
                setIsVisible(!reachedFooter);
                onVisibilityChange?.(!reachedFooter);
            } else {
                scrollListener();
            }
        });

        return () => {
            window.removeEventListener('scroll', scrollListener);
            cancelAnimationFrame(frameId);
        };
    }, [handleScroll, scrollThreshold, onVisibilityChange, reachedFooter]);

    return (
        <div
            className={cn(_floatingIslandVariants({ variant: 'default' }))}
            onClick={onClick}
        >
            <div
                className={cn(
                    _floatingIslandContentVariants({ variant }),
                    'transition-opacity transition-transform duration-300',
                    isVisible
                        ? 'translate-y-0 opacity-100'
                        : 'translate-y-full opacity-0',
                    // offset the scrollbar size to account for the scrollbar
                    '[body[data-scroll-locked="1"]_&]:-ml-[calc(var(--body-scroll-bar-size)/2)]',
                    '[body[data-scroll-locked="1"]_&]:mr-[calc(var(--body-scroll-bar-size)/2)]',
                )}
            >
                {children}
            </div>
        </div>
    );
};
